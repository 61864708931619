import {
  ChargebeeFeatureConfig,
  features as staticFeatures,
} from "@sciam/chargebee/constants.json";
import { ChargebeePlanConfig, plans as staticPlans } from "@sciam/chargebee/plans.json";
import { createContext, useContext } from "react";

import type { SciAmAuth0Payload, SciAmUserPayload } from "@/types";
import { useEnvironment } from "~core/hooks/use-environment";
import { useAuth } from "~features/auth";
import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";

/**
 * Get current user details.
 *
 * Merges user data from the Auth0 and SciAm JWTs to provide a unified user context.
 */
export function useUser() {
  const context = useContext(UserContext);
  if (!context) throw new Error("useUser must be used within a UserProvider");
  return context;
}

/**
 * User details for the current session
 *
 * @see useUser for the hook that provides this context
 * @see UserProvider for the provider that sets this context
 * @see SciAmAuth0Payload for the Auth0 JWT claims
 * @see SciAmUserPayload for the SciAm JWT claims
 */
interface SciAmUser {
  /**
   * A unique ID for a given user.
   *
   * This value is immune to changes of email, authentication method, or ecomm customer associations.
   *
   * This is the `sciam.com/user_id` claim in the Auth0 JWT.
   * It's mostly the same as the Auth ID but with the provider prefix stripped out.
   */
  userId: string;
  /**
   * The ID of the user's authentication record.
   *
   * An alias of the `sub` claim in the Auth0 JWT, this value will be prefixed with the auth provider, e.g. `auth0|`, `google-oauth2|`
   */
  authId: SciAmAuth0Payload["sub"];
  /**
   * This is (or will be) the ID of the user's primary Customer record in Chargebee.
   *
   * This value is the `sub` claim in the SciAm JWT, or computed from the Auth0 customers list.
   *
   * This is *generally* the same as the `userId` except for:
   * - Users who registered after receiving a gift subscription
   * - QA, dev, and test accounts for a dozen reasons
   * - Migration bugs
   */
  customerId: string;
  /** The user's email address */
  email: SciAmAuth0Payload["email"] | "";
  /** First Name */
  firstName: SciAmAuth0Payload["https://sciam.com/first_name"];
  /** Last Name */
  lastName: SciAmAuth0Payload["https://sciam.com/last_name"];
  /**
   * A list of all Chargebee Customer objects associated with the user.
   *
   * This list is derived directly from the Auth0 JWT's `https://sciam.com/customers` claim or
   * computed by SciAm JWT's `subscriptions` claim
   */
  customers: SciAmAuth0Payload["https://sciam.com/customers"][string];
  /** A list of all Chargebee Customer IDs associated with the user */
  customerIds: string[];
  /**
   * A partial list of subscriptions associated with the user. Most users should only have one subscription.
   *
   * This list currently only contains one subscription per Customer record.
   * It will either be the most recent active subscription or the first cancelled one.
   */
  subscriptions: SciAmUserPayload["subscriptions"];
  /**
   * IDs of features a user has access to, e.g. `["digital-access"]`
   *
   * `null` when backend fails to fetch Chargebee entitlements
   */
  featureIds: SciAmUserPayload["features"];
}
function useProvideUser(): SciAmUser {
  // Identifiers
  let userId = "";
  let authId = "";
  let customerId = "";

  // User details
  let email: SciAmUserContext["email"] = "";
  let firstName = "";
  let lastName = "";

  // Chargebee data
  let customerIds: string[];
  let customers: SciAmUserContext["customers"] = [];
  let subscriptions: SciAmUserContext["subscriptions"] = [];
  let featureIds: string[] = [];

  // TODO: Capture user email from newsletter signup form

  const { entitlements } = useEnvironment();
  const { user: authIdToken } = useAuth();
  if (authIdToken) {
    authId = authIdToken.sub;

    userId = authIdToken["https://sciam.com/user_id"];
    email = authIdToken.email as SciAmAuth0Payload["email"];
    firstName = authIdToken["https://sciam.com/first_name"] || authIdToken.given_name;
    lastName = authIdToken["https://sciam.com/last_name"] || authIdToken.family_name;

    customers = authIdToken["https://sciam.com/customers"]?.[entitlements?.environment] || [];
  }

  const { payload: sciamUserToken } = useSciAmJwtSync();
  if (sciamUserToken) {
    customerId = sciamUserToken.sub;

    // FYI, these values could be `null` in the SciAm JWT
    customerIds = sciamUserToken.customers || [];
    featureIds = sciamUserToken.features || [];
    subscriptions = sciamUserToken.subscriptions || [];

    // User detail fallback if the Auth0 JWT is missing
    // This could happen briefly during page load if the Auth0 cache expires
    userId ||= sciamUserToken["https://sciam.com/user_id"];
    email ||= sciamUserToken.email;
    firstName ||= sciamUserToken["https://sciam.com/first_name"] || sciamUserToken.given_name;
    lastName ||= sciamUserToken["https://sciam.com/last_name"] || sciamUserToken.family_name;
  }

  // Graceful fallback if the SciAm JWT goes missing or has null claims
  customerIds ||= customers.map((customer) => customer.id);
  customerId ||= customerIds[0];

  // It's possible for a Customer record to be "missing" from the Auth0 customer claim,
  // e.g. when a newly-registered user purchases a subscription
  if (customers?.length) {
    // Append any customers missing from the Auth0 JWT but found in the SciAm JWT
    if (customerIds.length > customers.length) {
      customerIds.forEach((customerId) => {
        if (customers.some((c) => c.id === customerId)) return;
        customers.push({ id: customerId });
      });
    }
  } else {
    // Fallback to Chargebee Customer IDs if the Auth0 JWT is missing
    customers = customerIds.map((id) => ({ id }));
  }

  return {
    userId,
    authId,
    customerId,
    email,
    firstName,
    lastName,
    customerIds,
    customers,
    subscriptions,
    featureIds,
  };
}

type Subscription = SciAmUserPayload["subscriptions"][number];
type Plan = ChargebeePlanConfig & { sub_id: string; status: string };

/**
 * User state enhanced with Chargebee data and computed flags
 * @extends SciAmUser
 */
interface SciAmUserContext extends SciAmUser {
  /**
   * The main subscription object to display to users.
   *
   * This value uses the following priority/fallback order:
   * 1. The user's primary active subscription
   * 2. The first active subscription
   * 3. The first primary subscription
   * 4. The first subscription
   * 4. `null`
   */
  subscription: Subscription | null;
  /**
   * The main plan object to display to users.
   * **Do not** assume this plan is active without checking its `status`.
   *
   * @see mainSubscription The subscription object that corresponds to this plan
   *
   * Depending on your use case, you may want to use one of the following:
   * @see primaryActivePlan
   * @see activePlans
   * @see primaryPlans
   * @see plans
   */
  plan: Plan | null;
  /**
   * All active subscriptions associated with the user.
   *
   * This will generally max out at one value unless the user has multiple Customer records, e.g.
   * after receiving a gift subscription.
   */
  activeSubscriptions: Subscription[];
  /**
   * All subscriptions associated with the user's primary Customer record of any status.
   *
   * This is currently limited to a single subscription to keep our JWT size down.
   */
  primarySubscriptions: Subscription[];
  /** The first user subscription that's active and associated with the primary Customer record */
  primaryActiveSubscription?: Subscription;
  /**
   * A list of feature objects a user has access to
   *
   * @example [{ id: "digital-access", name: "Digital Access", description: "...etc" }]
   */
  features: Array<ChargebeeFeatureConfig>;
  /**
   * A list of all Chargebee Plan objects associated with the user. **May include cancelled plans**
   *
   * This list is derived from the user's subscriptions and includes the status of each subscription.
   */
  plans: Plan[];
  /**
   * A list of all active Chargebee Plan objects associated with the user.
   * This list is a subset of `plans`.
   * @see plans
   */
  activePlans: Plan[];
  /**
   * A list of all Chargebee Plan objects associated with the user's primary Customer record.
   * This list is a subset of `plans`.
   * @see plans
   */
  primaryPlans: Plan[];
  /**
   * The user's primary active Chargebee Plan object.
   */
  primaryActivePlan: Plan;

  //
  // Flags
  //
  /** Whether the user is currently logged in */
  isLoggedIn: boolean;
  /** Whether the user is known SciAm or Springer-Nature staff */
  isStaff: boolean;
  /** Whether the user has an *active* subscription that provides entitlements */
  isActiveSubscriber: boolean;
}
const UserContext = createContext<SciAmUserContext>(null);

export function UserProvider({ children }) {
  const user = useProvideUser();

  // Additional computed data so we don't have to do this in every component
  const { userId, customerId, email, featureIds, subscriptions } = user;
  const computedSubs = getUserSubscriptionDetails(subscriptions, customerId);
  const computedPlans = getUserPlans(subscriptions, computedSubs);
  const features = staticFeatures.filter((feature) => featureIds.includes(feature.id));
  const subscription =
    computedSubs.primaryActiveSubscription ||
    computedSubs.activeSubscriptions[0] ||
    computedSubs.primarySubscriptions[0] ||
    subscriptions[0] ||
    null;
  const plan =
    computedPlans.primaryActivePlan ||
    computedPlans.activePlans[0] ||
    computedPlans.primaryPlans[0] ||
    computedPlans.plans[0] ||
    null;

  return (
    <UserContext.Provider
      value={{
        ...user,

        // Computed data based on SciAm JWT and static Chargebee data
        features,
        plan,
        subscription,
        ...computedSubs,
        ...computedPlans,

        // Flags
        isLoggedIn: !!userId,
        isStaff: email?.endsWith("@sciam.com") || email?.endsWith("@springernature.com"),
        isActiveSubscriber: !!featureIds.length || !!computedSubs.activeSubscriptions.length,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

const ACTIVE_PLAN_STATUSES = ["active", "in_trial", "non_renewing"];

/** Compute the user's active and primary subscriptions */
export const getUserSubscriptionDetails = (
  subscriptions: Subscription[],
  primaryCustomerId: string,
) => {
  let primaryActiveSubscription: Subscription | undefined = undefined;
  const activeSubscriptions: Subscription[] = [];
  const primarySubscriptions: Subscription[] = [];

  // Logged out, bail early
  if (!primaryCustomerId) return { activeSubscriptions, primarySubscriptions };

  for (const sub of subscriptions) {
    const isPrimary = sub.customer_id === primaryCustomerId;
    const isActive = ACTIVE_PLAN_STATUSES.includes(sub.status);
    if (isActive) activeSubscriptions.push(sub);
    if (isPrimary) primarySubscriptions.push(sub);
    if (isPrimary && isActive) primaryActiveSubscription ||= sub;
  }

  return {
    activeSubscriptions,
    primarySubscriptions,
    primaryActiveSubscription,
  };
};

/** Compute the user's plans based on their subscriptions and our static Plan data */
export const getUserPlans = (
  subscriptions: SciAmUserPayload["subscriptions"],
  computed: ReturnType<typeof getUserSubscriptionDetails>,
) =>
  staticPlans.reduce(
    (acc, plan: Plan) => {
      const matchSub = (sub: Subscription) => sub.price_id === plan.default_price_id;
      const subscription = subscriptions?.find(matchSub);
      if (!subscription) return acc;
      plan["sub_id"] = subscription.id;
      plan["status"] = subscription.status;

      // Subtle detail: Static plans are ordered from lowest tier (Digital) to highest (Unlimited)
      // By using unshift, we ensure the highest tier plan is listed first
      acc.plans.unshift(plan);
      if (computed.activeSubscriptions.some(matchSub)) acc.activePlans.unshift(plan);
      if (computed.primarySubscriptions.some(matchSub)) acc.primaryPlans.unshift(plan);
      if (plan.default_price_id === computed.primaryActiveSubscription?.price_id) {
        // The highest tier plan that's active and associated with the primary Customer record
        acc.primaryActivePlan = plan;
      }

      return acc;
    },
    {
      plans: [] as Plan[],
      activePlans: [] as Plan[],
      primaryPlans: [] as Plan[],
      primaryActivePlan: null,
    },
  );
